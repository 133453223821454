*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	background: #add2f9;
}
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.section-404,
.section-error-bounderies {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	text-align: center;
}

.section-404 .header,
.section-error-bounderies .header {
	font-size: 40px;
	font-weight: 600;
	color: rgb(119, 121, 134);
	margin-bottom: 20px;
	line-height: 1;
}

.section-404 .text,
.section-error-bounderies .text {
	font-size: 15px;
	font-weight: 500;
	color: #101223;
	margin-bottom: 30px;
}

.notifications-container {
	position: fixed;
	top: 20px;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
	overflow-x: scroll;
	pointer-events: none;
}
.notifications-container .ant-space-item {
	pointer-events: auto;
}
.table-pro {
	width: 100%;
	background: #fff;
	border-radius: 4px;
	border-collapse: separate;
	border-spacing: 0;
	overflow: hidden;
}

.table-pro thead {
	border-radius: 20px 20px 0 0;
}

.table-pro thead th,
.table-pro tbody tr td {
	font-size: 15px;
	color: #666;
	line-height: 1.2;
	font-weight: unset !important;
	padding: 20px 15px;
}

.table-pro thead th {
	color: #fff;
	background: #1b1d1f;
}

.table-pro tbody tr td {
	border-bottom: 1px solid #f2f2f2;
}

.table-pro thead th:first-child,
.table-pro tbody tr td:first-child {
	padding-left: 30px;
}

.table-pro thead th:last-child,
.table-pro tbody tr td:last-child {
	border-right: none;
}

.table-pro tbody tr:last-child td {
	border-bottom: none;
}

.table-pro tbody tr:hover {
	background-color: #f0f6ff;
}
.table-pro td span {
	font-size: 13px;
}

.table-simple {
	width: 100%;
	background: unset;
	border-collapse: separate;
	border-radius: 0;
	overflow: hidden;
	/* table-layout: fixed; */
}
.table-simple thead th,
.table-simple tbody tr td {
	font-size: 13px;
	color: #666;
	line-height: 1.2;
	font-weight: unset !important;
	padding: 3px;
	display: table-cell;
	box-sizing: border-box; /* Ensure padding and border are included in the width */
}
.table-simple thead th:first-child,
.table-simple tbody tr td:first-child {
	padding-left: 3px;
}

.table-simple thead th {
	color: #1b1d1f;
	background: unset;
	font-weight: bold;
}

.table-simple tbody tr td {
	border-bottom: unset;
}

.no-padding {
	padding: 0 !important;
}
.no-margin {
	margin: 0 !important;
}

.form-disabled-will-display-input-normal input {
	background: unset !important;
	color: #666 !important;
}

.form-disabled-will-display-input-normal .ant-select-selector {
	background: unset !important;
	color: #666 !important;
}

.form-disabled-will-display-input-normal .ant-segmented-item-selected {
	color: #fff !important;
}

.form-disabled-will-display-input-normal .ant-picker-disabled {
	background: unset !important;
	color: #666 !important;
}

/* 
* Ant Design overrides
*/
.ant-segmented:not(.ant-segmented-disabled) {
	background: unset;
}

.ant-segmented-item-selected .ant-segmented-item-label {
	color: #ffffffde;
}
.dynamic-form-wrapper {
	position: relative;
	border: 1px dashed #ccc; /* Dashed border */
	border-radius: 5px;
	padding: 5px;
}
.dynamic-form-wrapper .dynamic-form-wrapper-bg,
.dynamic-form-wrapper .dynamic-form-wrapper-add-input {
	margin-left: 15px !important;
}

.dynamic-form-wrapper-bg {
	padding: 5px;
	border-radius: 5px;
}

.dynamic-form-wrapper-bg.odd {
	background-color: #c18ec317; /* for even rows */
}

.dynamic-form-wrapper-bg.even {
	background-color: #8ea4c317; /*  odd rows */
}

.dynamic-form-wrapper .ant-select {
	display: block;
	margin-bottom: 10px; /* Adds the space between <Select> and input */
}
.dynamic-form-wrapper .ant-select-selector {
	border-style: dashed !important;
}

.dynamic-form-wrapper:not(.dynamic-form-wrapper-view-mode)::after {
	content: '';
	position: absolute;
	left: 50%;
	width: 1px;
	height: 7px; /* Adjust height to match the gap size */
	background-color: #ccc;
	top: 37px; /* Adjust this to position the line between the <Select> and input */
}

.dynamic-form-wrapper:not(.dynamic-form-wrapper-view-mode)::before {
	content: '➤'; /* Arrow symbol */
	position: absolute;
	left: calc(50% - 3px); /* Adjust this to center the arrow properly */
	top: 38px; /* Adjust this for correct positioning */
	color: #ccc; /* Color of the arrow */
	font-size: 8px;
	transform: rotate(90deg); /* Rotates the arrow to point downward */
}
