.cover-container {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.video-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.content {
	text-align: center;
	z-index: 2;
	color: #fff;
}
