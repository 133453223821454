body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.filter-advanced {
  display: none;
}

.filter-advanced.visible {
  display: flex;
  align-items: center;
}

.filter-advanced.hidden {
  display: none;
}

.privacy-popover {
  position: fixed;
  z-index: 999;
  right: 50px;
  bottom: 50px;
  left: 50px;
  background-color: white;
  border: 2px solid #ebedef;
  border-radius: 8px;
  padding: 20px;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-top-color: #4846f4;
  border-top-style: solid;
  border-top-width: 25px;
  border-right-color: #ebedef;
  border-right-style: solid;
  border-right-width: 50px;
  border-bottom-color: #4846f4;
  border-bottom-style: solid;
  border-bottom-width: 25px;
  border-left-color: #ebedef;
  border-left-style: solid;
  border-left-width: 50px;
  overflow: scroll;
  position: fixed;
  z-index: 999;
  right: 50px;
  bottom: 50px;
  left: 50px;
}
