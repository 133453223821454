.section-pattern-dots {
	background-image: radial-gradient(
		circle,
		rgba(128, 81, 81, 0.04) 1px,
		transparent 1px
	);
	background-size: 5px 5px;
}

.section-pattern-notes {
	background-image: linear-gradient(rgba(0, 0, 0, 0.03) 1px, transparent 1px);
	background-size: 100% 40px;
}

.view-only-styles {
	background: none !important;
	border: none !important;
}
	
.view-only-styles .ant-input {
	background: transparent;
	border: none;
	color: #000 !important;
}
	
.view-only-styles .ant-input-number {
	background: transparent;
	border: none;
	color: #000 !important;
}

.view-only-styles .ant-select {
	background: transparent !important;
	border: none !important;
}

.view-only-styles .ant-select-selector {
	background: transparent !important;
	border: none !important;
	color: #000 !important;
}

.view-only-styles .ant-select-suffix {
	display: none;
}

.view-only-styles .ant-picker {
	background: transparent !important;
	border: none !important;
}

.view-only-styles .ant-picker-input > input{
    background: transparent !important;
    border: none !important;
    color: #000 !important;
}

.view-only-styles .ant-picker-suffix {
    display: none !important;
}